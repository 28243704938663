import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AdminComponent } from 'src/app/coffee-shop/admin/admin.component';
import { AuthGuardGuard } from 'src/app/coffee-shop/auth-guard.guard';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    useHash: false
};

const routes: Routes = [
	{
		path: 'admin',
		component: AdminComponent,
		loadChildren: () => import('./coffee-shop/admin/admin.module').then((m) => m.AdminModule),
		canActivate: [AuthGuardGuard],
	},

	{
		path: 'magusafoodbank',
		loadChildren: () =>
			import('./food-distribution/food-distribution.module').then((m) => m.FoodDistributionModule),
	},
	{
		path: 'mfb',
		redirectTo: 'magusafoodbank',
	},
	{
		path: 'foodbank',
		redirectTo: 'magusafoodbank',
	},
	{
		path: 'magusa_food_bank',
		redirectTo: 'magusafoodbank',
	},
	{
		path: 'food',
		redirectTo: 'magusafoodbank',
	},
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, routerOptions), //
	],
	exports: [],
})
export class AppRoutingModule {}
