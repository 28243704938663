import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const URL = 'https://us-central1-firebase-campus-hub.cloudfunctions.net/dash';
//"https://us-central1-tupakase-app.cloudfunctions.net/dash";

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	constructor(private http: HttpClient) {}

	makePayment(data) {
		return this.http.post(`${URL}/stripePay`, data);
	}

	createIntent(data) {
		return this.http.post(`${URL}/create-payment-intent`, data);
	}

	updateIntent(id: string, email: string, intent, quantity, name) {
		const data = {
			id,
			email,
			intent,
			quantity,
			name,
		};
		return this.http.post(`${URL}/updateIntent`, data);
	}
}
