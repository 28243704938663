import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-our-food',
	templateUrl: './our-food.component.html',
	styleUrls: ['./our-food.component.scss'],
})
export class OurFoodComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
