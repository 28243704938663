import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
	title: string;
	bgImg: string;
	description: Array<any>;
	imgs: Array<any>;
	guides: string;
	guidesInstruction: { guide: string }[];
	nextEvent: string;
	eventType: string;
	constructor(private route: ActivatedRoute, private router: Router) {}

	loadEvent(type: string, state) {
		this.eventType = type;
		switch (type) {
			case 'exhibitions':
				this.nextEvent = 'open-mic';
				if (state) {
					this.router.navigateByUrl(`events/${type}`);
				}

				this.imgs = [
					'assets/arts/art1.jpg',
					'assets/arts/art3.jpg',
					'assets/arts/art4.jpg',
					'assets/arts/art43.jpg',
					'assets/arts/art34.jpg',
					'assets/arts/art3d.jpg',
					'assets/arts/arty.jpg',
					'assets/arts/artcafe.jpg',
				];
				this.bgImg = '../../assets/art4.jpg';
				this.title = 'Art & Photo Exhibitions';

				this.description = [
					{
						description: `Exile Cafe features a rotating exhibit of artists' works.
             Art is a valuable part of the cultural identity of a place and its people, and we want to encourage and promote beautiful
             expressions of that identity as part of the vision for our space.
`,
					},
					{
						description: `We want to prioritize local artists and their work, so we seek
             out Cypriot artists and artists living in Cyprus, although we may choose to display work from visiting
             artists from other places as well.`,
					},
					{
						description: `We aim to rotate the featured art at least every two months, hosting a gallery opening
            event for each new artist being exhibited.`,
					},
					{
						description: `We carefully curate the art on display, choosing work that is aesthetically beautiful
            and technically excellent, and which
            fits with our ethos. We value building a relationship with the artists, giving them an opportunity
            to express themselves and seeking to promote their work`,
					},
				];
				this.guides = `Some guidelines and logistics:`;
				this.guidesInstruction = [
					{
						guide: `We invite artists to submit an artist’s statement that will be printed for visitors to read when they come to see the exhibit.
              If artists wish to sell their art, we display prices on the page with the artist’s statement, rather than on the art itself.
               Exile Cafe will not take a commission on the sale of art.
               The artists are free to determine prices for their work and 100% of the sale price will go to the artist.`,
					},
					{
						guide: `Artists wishing to display their work at Exile Cafe can make an appointment to meet with us. We will get to know the artist, review some of their work, and ask them to fill out a simple request form so that we can write back to let the artist know whether we would like to have their work displayed at Exile Cafe. `,
					},
					{
						guide: `When we invite an artist to display their work, we will walk them through the space and make decisions with them about where to display the pieces we choose together. We may ask the artist to help mount the work, especially if it requires special equipment, materials, or labor.`,
					},
					{
						guide: `All of the art remains the property of the artist. Exile Cafe staff and management will take extra care to supervise the space and protect the art on display, but we cannot be held liable for damage that occurs while it is at Exile Cafe. We may choose to display more than one artist’s work at a time depending on the availability of space and the number of pieces we want to display from a particular artist.`,
					},
				];
				break;

			case 'open-mic':
				this.nextEvent = 'exhibitions';

				if (state) {
					this.router.navigateByUrl(`events/${type}`);
				}
				this.imgs = [
					'assets/mic/opc3.jpg',
					'assets/mic/opc.jpg',
					'assets/mic/opc2.jpg',
					'assets/mic/op4.jpg',
					// "assets/arts/art34.jpg",
					// "assets/arts/art3d.jpg"
				];
				this.bgImg = '../../assets/art4.jpg';
				this.title = 'Open Mic';
				this.description = [
					{
						description: `Every Friday night at 7:00 PM we host an Open Mic Night. Anyone is welcome to contribute a song, a reading, a poem, a magic trick, etc.`,
					},
					{
						description: `We want to create a venue for people to develop and showcase their talents. And we want to encourage genuine connections and real community. `,
					},
					{
						description: `Every Friday also features a special gourmet menu item.`,
					},
				];
				break;
			default:
				break;
		}
	}

	ngOnInit() {
		const type = this.route.snapshot.paramMap.get('id');
		console.log(type);
		this.loadEvent(type, false);
	}
}
