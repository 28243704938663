import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { HomeComponent } from 'src/app/coffee-shop/home/home.component';
import { MenuComponent } from 'src/app/coffee-shop/menu/menu.component';
import { WhatWeOfferComponent } from '../what-we-offer/what-we-offer.component';
import { AboutUsComponent } from 'src/app/coffee-shop/about-us/about-us.component';
import { EventsComponent } from 'src/app/coffee-shop/events/events.component';
import { ExileWineComponent } from 'src/app/coffee-shop/exile-wine/exile-wine.component';
import { LoginComponent } from 'src/app/coffee-shop/login/login.component';
import { ProfileComponent } from 'src/app/coffee-shop/profile/profile.component';
const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    useHash: true
};
const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{ path: 'home', component: HomeComponent },
			{ path: 'menu', component: MenuComponent },
			{ path: 'what-we-offer', component: WhatWeOfferComponent },
			{ path: 'about-us', component: AboutUsComponent },
			{ path: 'events/:id', component: EventsComponent },
			{ path: 'exile-wine', component: ExileWineComponent },
			{ path: 'login', component: LoginComponent },
			{ path: 'profile', component: ProfileComponent },
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{ path: '**', redirectTo: 'home', pathMatch: 'full' },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LayoutsRoutingModule {}
