import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs/Subscription';
// tslint:disable-next-line: no-var-keyword
let lastScrollTop = 0;
const delta = 5;
const navbarHeight = 0;
// tslint:disable-next-line: no-var-keyword
@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
	darkLogo = './assets/exile-2.png';
	whiteLogo = './assets/Exilewhite.png';
	logo = this.darkLogo;
	private _router: Subscription;

	constructor(
		private renderer: Renderer2,
		private router: Router,
		@Inject(DOCUMENT) private document: any,
		private element: ElementRef,
		public location: Location,
		private wowService: NgwWowService
	) {}

	@HostListener('window:scroll', ['$event'])
	onScroll(event: any) {
		// let currentSection: string;
		const children = this.element.nativeElement.children;
		const scrollTop = event.target.scrollTop;
		const parentOffset = event.target.offsetTop;
		console.log(children);

		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < children.length; i++) {
			const element = children[i];
			console.log(element.tagname);

			// if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
			//   if (element.offsetTop - parentOffset <= scrollTop) {
			//     currentSection = element.id;
			//   }
			// }
		}
		// if (currentSection !== this.currentSection) {
		//   this.currentSection = currentSection;
		//   this.sectionChange.emit(this.currentSection);
		// }
	}
	@HostListener('window:scroll', ['$event'])
	hasScrolled() {
		const st = window.pageYOffset;
		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}

		const navbar = document.getElementsByTagName('nav')[0];
		// const ss = document.getElementsByTagName("app-home")[0];
		// console.log(ss);

		// If they scrolled down and are past the navbar, add class .headroom--unpinned.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			if (navbar.classList.contains('headroom--pinned')) {
				navbar.classList.remove('headroom--pinned');
				navbar.classList.add('headroom--unpinned');
			}
			// $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
		} else {
			// Scroll Up
			//  $(window).height()
			if (st + window.innerHeight < document.body.scrollHeight) {
				// $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
				if (navbar.classList.contains('headroom--unpinned')) {
					navbar.classList.remove('headroom--unpinned');
					navbar.classList.add('headroom--pinned');
				}
			}
		}

		lastScrollTop = st;
	}
	ngOnInit(): void {
		this.wowService.init();

		const navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
		this._router = this.router.events
			.filter((event) => event instanceof NavigationEnd)
			.subscribe((event: NavigationEnd) => {
				this.wowService.init();
				if (window.outerWidth > 991) {
					window.document.children[0].scrollTop = 0;
				} else {
					window.document.activeElement.scrollTop = 0;
				}
				// tslint:disable-next-line:no-shadowed-variable
				this.renderer.listen('window', 'scroll', (event: any) => {
					// let currentSection: string;
					// const children = document.getElementsByTagName("app-home")[0]
					//   .children;
					// const scrollTop = event.target.scrollTop;
					// const parentOffset = event.target.offsetTop;

					// for (let i = 0; i < children[0].children.length; i++) {
					//   const element = children[i];
					//   if (element) {
					//     console.log(element.children.);
					//   }

					//   // if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
					//   //   if (element.offsetTop - parentOffset <= scrollTop) {
					//   //     currentSection = element.id;
					//   //   }
					//   // }
					// }
					const num = window.scrollY;
					if (num > 150 || window.pageYOffset > 150) {
						// add logic
						navbar.classList.add('headroom--not-top');
						this.logo = this.whiteLogo;
					} else {
						// remove logic
						navbar.classList.remove('headroom--not-top');
						console.log('not top');
						if (navbar.classList.contains('menu-page') && (num < 150 || window.pageYOffset < 150)) {
							this.logo = this.whiteLogo;
						} else {
							this.logo = this.darkLogo;
						}
					}
				});
			});
		this.hasScrolled();
	}
}
