import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent, ViewportScroller } from '@angular/common';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	@Input()
	logo = '';
	public isCollapsed = true;
	private lastPoppedUrl: string;
	private yScrollStack: number[] = [];
	isMenuPage = false;
	darkLogo = './assets/exile-2.png';
	whiteLogo = './assets/Exilewhite.png';
	//   logo: string;

	constructor(
		public location: Location,
		private scroller: ViewportScroller,
		private router: Router
	) {}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
			if (event instanceof NavigationStart) {
				if (event.url !== this.lastPoppedUrl) {
					this.yScrollStack.push(window.scrollY);
				}
			} else if (event instanceof NavigationEnd) {
				console.log(event.url.split('#')[1]);
				this.isHome();
				/* if (event.url === this.lastPoppedUrl) {
					this.lastPoppedUrl = undefined;
					window.scrollTo(0, this.yScrollStack.pop());
				} else {
					window.scrollTo(0, 0);
				} */
				const ff = document.getElementById(event.url.split('#')[1]);
				if (ff) {
					ff.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'nearest',
					});
				}
				const scrolto = '';
			}
		});
		this.location.subscribe((ev: PopStateEvent) => {
			this.lastPoppedUrl = ev.url;
		});
	}

	isHome() {
		const titlee = this.location.prepareExternalUrl(this.location.path());
		console.log(titlee);
		if (titlee.includes('home')) {
			this.logo = this.whiteLogo; // this.darkLogo;
			this.isMenuPage = false;
			return false;
		} else {
			//   this.logo = this.darkLogo;

			if (titlee === '#/menu' || titlee === '#/exile-wine') {
				this.isMenuPage = true;
				console.log(titlee);
				this.logo = this.whiteLogo;
			} else {
				this.isMenuPage = false;
			}

			return;
		}
	}
	isDocumentation() {
		const titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee === '#/documentation') {
			return true;
		} else {
			return false;
		}
	}
}
