import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Subscription } from 'rxjs';
// import * as qs from "qs";
import axios from 'axios';
// import * as xml2js from "xml2js";
const baseURL = 'https://studentgateway.gov.ct.tr/main.asmx';
export const API = axios.create({
	baseURL,
});

// const postConfig = {
// 	headers: {
// 		"Content-Type": "application/x-www-form-urlencoded"
// 	}
// };

/*export function unescapeHTML(escapedHTML) {
	return escapedHTML
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">")
		.replace(/&amp;/g, "&");
}*/
/*

export function parser(response, type) {
	return new Promise((resolve, reject) => {
		xml2js.parseString(unescapeHTML(response), (err, result) => {
			const res = result.string._;
			let code = null;
			let data = null;
			let totalRows = null;
			if (type === "S") {
				code = res.substring(0, 3);
				data = res.substring(5);
			} else {
				code = res.substring(0, 3);
				if (result.string && result.string.NewDataSet) {
					for (const k in result.string) {
						if (k !== "$" && k !== "_") {
							data = result.string[k][0].Table;
							if (result.string[k][0] && result.string[k][0].$) {
								if (result.string[k][0].$ && result.string[k][0].$.RowsCount) {
									totalRows = parseInt(result.string[k][0].$.RowsCount, 10);
								}
							}
						}
					}
				} else {
					totalRows = 0;
				}
				if (data === null) {
					code = res.substring(0, 3);
					data = res.substring(5);
				}
			}

			if (code === "001") {
				if (totalRows !== null) {
					data = { data, totalRows };
					if (totalRows === 0) {
						data = { data: [], totalRows };
					}
				}

				resolve(data);
			} else {
				reject(data);
			}
		});
	});
}
*/

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	model = {
		left: true,
		middle: false,
		right: false,
	};
	focus;
	focus1;
	private wowSubscription: Subscription;
	constructor() {}
	flattenItems = (items) => {
		const flatItems = [];
		items.map((item) => {
			const flatItem = {};
			// tslint:disable-next-line:forin
			for (const k in item) {
				flatItem[k] = item[k][0];
			}
			flatItems.push(flatItem);
			return null;
		});
		return flatItems;
	};
	ngOnInit() {
		/*	console.log(
			qs.stringify({
				sStudentNo: "21813356",
				sName: "DEREK DEINMA",
				sSurname: "JAMABO",
				sMotherName: "ESTHER",
				sFatherName: "JAMABO",
				sBirthDate: "1989-09-14T00:00:00+02:00",
				sMobileNo: "05338369565",
				sEmail: "derekjamabo@live.co.uk",
				sNationality: "NİJERYA / NIGERIA"
			})
		);*/
		// API.post(baseURL);
		// this.getAllUniversities();
		// this.changeContactInformation();
		// sStudentNo,
		// 	sUniversityNo,
		// 	sName,
		// 	sSurname,
		// 	sMotherName,
		// 	sFatherName,
		// 	sBirthDate,
		// 	sMobileNo,
		// 	sEmail,
		// 	sNationality;
		//  <Table>
		// 		<UniversityRecordNo>9</UniversityRecordNo>
		// 		<UniversityName>Uluslararası Kıbrıs Üniversitesi / CIU</UniversityName>
		// 		<RegionName>LEFKOŞA</RegionName>
		// 		<UniversityShortName>009</UniversityShortName>
		// 		<UniversityAddress>Lefkoşa</UniversityAddress>
		// 		<UniversityHiggerEducationCode>202951</UniversityHiggerEducationCode>
		// 		<UniversityIsUniversity>1</UniversityIsUniversity>
		// 	</Table>;
	}

	/*async changeContactInformation() {
		const params = {
			sStudentNo: "21813356",
			sUniversityNo: "9",
			sName: "DEREK DEINMA",
			sSurname: "JAMABO",
			sMotherName: "ESTHER",
			sFatherName: "JAMABO",
			sBirthDate: "1989-09-14T00:00:00+01:00",
			sMobileNo: "05338369565",
			sEmail: "derekjamabo@live.co.uk",
			sNationality: "NİJERYA / NIGERIA"
		};

		console.log(params);
		const data = await API.post(
			"/_ChangeContactInformation",
			qs.stringify(params),
			postConfig
		)
			.then(d => {
				console.log(d);
			})
			.catch(e => console.log(e.response));
		console.log(data);
	}*/

	async getAllUniversities() {
		const data = await API.get('/_GetAllUniversities').catch((e) => console.log(e));
		// return parser(data['data'], 'S')
		// 	.then((d) => {
		// 		console.log(d);
		// 	})
		// 	.catch((e) => console.log(e));
	}

	imageLoaded() {
		console.log('loaded');
	}
	videoLoaded(event) {
		console.log('event');
	}
	onSectionChange(sectionId: string) {
		console.log(sectionId);
	}

	ngOnDestroy() {
		// unsubscribe (if necessary) to WOW observable to prevent memory leaks
		// this.wowSubscription.unsubscribe();
	}
}
