import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// tslint:disable-next-line:no-submodule-imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';

import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { NgwWowModule } from 'ngx-wow';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
	getAnalytics,
	provideAnalytics,
	ScreenTrackingService,
	UserTrackingService,
} from '@angular/fire/analytics';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { HttpClientModule } from '@angular/common/http';

import { CoffeeShopModule } from 'src/app/coffee-shop/coffee-shop.module';
import { connectDatabaseEmulator, getDatabase, provideDatabase } from '@angular/fire/database';
import { ToastrModule } from 'ngx-toastr';
import { LoadingComponent } from 'src/app/food-distribution/components/loading/loading.component';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
	resolvePersistenceEnabled = resolve;
});

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(), // ToastrModule added
		NgbModule,
		NgwWowModule,
		FormsModule,
		SwiperModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		AppRoutingModule,
		CoffeeShopModule,
		FormsModule,
		RouterModule,
		JwBootstrapSwitchNg2Module,
		provideFirebaseApp(() => {
			return initializeApp(environment.firebase);
		}),
		provideAuth(() => {
			const auth = getAuth();
			if (environment.useEmulators) connectAuthEmulator(auth, `http://localhost:9099`);
			return auth;
		}),
		provideDatabase(() => {
			const db = getDatabase();
			if (environment.useEmulators) connectDatabaseEmulator(db, 'localhost', 9000);
			return db;
		}),
		provideFirestore(() => {
			const firestore = getFirestore();
			if (environment.useEmulators) connectFirestoreEmulator(firestore, 'localhost', 8080);
			return firestore;
		}),
		provideAnalytics(() => {
			const analytics = getAnalytics();
			return analytics;
		}),
		provideFunctions(() => {
			const functions = getFunctions();
			if (environment.useEmulators) connectFunctionsEmulator(functions, 'localhost', 5001);
			return functions;
		}),
		provideMessaging(() => getMessaging()),
		providePerformance(() => getPerformance()),
		provideRemoteConfig(() => getRemoteConfig()),
		provideStorage(() => getStorage()),
		/*		AngularFireModule.initializeApp(environment.firebase),
		AngularFireStorageModule,*/
	],
	providers: [
		ScreenTrackingService,
		UserTrackingService,
		{ provide: USE_AUTH_EMULATOR, useValue: { appVerificationDisabledForTesting: true } },
		/*{
			provide: USE_AUTH_EMULATOR,
			useValue: environment.useEmulators ? ['localhost', 9099] : undefined,
		},
		{
			provide: USE_FIRESTORE_EMULATOR,
			useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
		},
		{
			provide: USE_FUNCTIONS_EMULATOR,
			useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
		},*/
		// { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
		// { provide: FUNCTIONS_ORIGIN, useFactory: () => (isDevMode() ? undefined : location.origin) },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
