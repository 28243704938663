import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DataSourceService } from 'src/app/coffee-shop/services/data-source.service';
import SwiperCore, { EffectCards } from 'swiper';
import { isEmpty } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryComponent } from 'src/app/coffee-shop/menu/components/gallery/gallery.component';
import { TranslateService } from '@ngx-translate/core';

SwiperCore.use([EffectCards]);

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, OnDestroy {
	customOptions: OwlOptions = {
		loop: false,
		autoWidth: false,
		mouseDrag: true,
		touchDrag: true,
		autoplay: false,
		pullDrag: true,
		dots: true,
		navSpeed: 700,
		navText: ['Prev', 'Next'],
		responsive: {
			0: {
				items: 1,
			},
			360: {
				items: 1,
			},
			400: {
				items: 1,
			},
			740: {
				items: 1,
			},
			768: {
				items: 1,
			},
			940: {
				items: 1,
			},
			1024: {
				items: 2,
			},
		},
		nav: true,
	};

	currentMenu: Observable<any[]>;
	currentMenuLang = 'menu_en';
	menuShowing = new BehaviorSubject([]);
	loaded = false;
	menuCategories = [];
	selectedLanguageMenu: any = {};
	selectedCategory = '';
	subMenu: Subscription;
	activeLanguage: string;

	constructor(
		private route: ActivatedRoute,
		private dataSource: DataSourceService,
		private modalService: NgbModal
	) {
		this.getMenu('menu_en');
		this.activeLanguage = 'en';
		// Todo: use the menu source for analytics in firebase
		this.route.queryParams
			.filter((params) => params.source)
			.subscribe((params) => {
				console.log(params.source);
			});
	}

	changeMenu(selectedMenu) {
		this.menuShowing.next(this.selectedLanguageMenu[selectedMenu].menus ?? []);
		this.selectedCategory = selectedMenu;
	}

	changeLanguage(lang: string) {
		this.activeLanguage = lang;
	}

	getMenu(lang) {
		const currentMenu = this.dataSource.Col$(lang);
		this.subMenu = currentMenu.subscribe((menu) => {
			if (menu) {
				const menuItems: any = {};
				const chunkSize = 10;
				menu.forEach((item) => {
					if (!item.deleted && item.isPublished) {
						const mn = item.items?.filter((i) => !i.deleted) ?? [];
						menuItems[item.name] = {
							menus: mn,
							extraInfo: item.extraInfo ?? null,
							name_tr: item.name_tr,
							pageCount: 1,
						};
					}
				});
				this.menuCategories = Object.entries(menuItems).map((it) => {
					return { id: it[0].toLowerCase(), name: it[0], name_tr: it[1]['name_tr'] };
				});
				if (!isEmpty(menuItems)) {
					this.selectedLanguageMenu = menuItems;
					const preSelected = this.menuCategories[0].name;
					this.menuShowing.next(menuItems[preSelected]?.menus);
					this.selectedCategory = preSelected;
					this.loaded = false;
				} else {
					this.menuShowing.next([]);
					this.loaded = true;
				}
			} else {
				this.menuShowing.next([]);
				this.loaded = true;
			}
			this.loaded = true;
		});
	}

	onSwiper(e) {}

	onSlideChange() {}

	ngOnInit(): void {
		document.getElementById('exile').style.overflowX = 'hidden';
	}

	ngOnDestroy(): void {
		document.getElementById('exile').style.overflowX = 'auto';
		if (this.subMenu) this.subMenu.unsubscribe();
	}

	showImages(menu: any) {
		const modalRef = this.modalService.open(GalleryComponent, {
			size: 'lg',
			centered: true,
		});
		modalRef.componentInstance.images = menu.images.map((i) => i.url);
		modalRef.componentInstance.name = menu.name;
	}
}
