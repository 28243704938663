import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	constructor() {}

	getMenu() {
		return {
			food: [
				{
					category: 'GRILLED CHEESE BAR',
					extraInfo: [],
					image: [],
					items: [
						{
							currency: 'TL',
							description:
								'A classic grilled cheese sandwich with our perfect blend of cheeses. It’s gooey, stretchy, and satisfying Pairs perfectly with our Öz Lale wine.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							image: [],
							measurement: '',
							name: 'BARNEY',
							sideNote: '',
							orderNumber: 1,
							price: 28,
							unit: '',
						},
						{
							currency: 'TL',
							description: 'Grilled chicken and kasar cheese with our own honey mustard.',
							extraInfo: [],
							foodType: '',
							image: [],
							measurement: '',
							name: 'BENJI',
							sideNote: '',
							orderNumber: 2,
							price: 28,
							unit: '',
						},
						{
							currency: 'TL',
							description: 'Halloumi and sour cherry jam with fresh basil. Tastes like Cyprus.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							image: [],
							measurement: '',
							name: 'RUBY',
							sideNote: '',
							orderNumber: 1,
							price: 28,
							unit: '',
						},
						{
							currency: 'TL',
							description:
								'Spicy chicken with gouda cheese and our garlic and pepper spread. It hurts good.',
							extraInfo: [],
							foodType: '',
							image: [],
							measurement: '',
							name: 'OZIE',
							sideNote: '',
							orderNumber: 1,
							price: 33,
							unit: '',
						},
						{
							currency: 'TL',
							description:
								'Bacon and cheese with fig jam. Sweet and salty and perfectly balanced. Pairs well with our Alev wine.',
							extraInfo: [],
							foodType: 'PORK',
							image: [],
							measurement: '',
							name: 'PIGGY',
							sideNote: '',
							orderNumber: 1,
							price: 40,
							unit: '',
						},
					],
					orderNumber: 1,
				},
				{
					category: 'BREAKFAST',
					orderNumber: 2,
					image: [],
					items: [
						{
							currency: 'TL',
							description:
								'Thick cut toast dipped in our perfectly seasoned egg mix and cooked on the skillet. Served with our homemade syrup, thick whipped cream, and  fresh fruit.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							image: [],
							measurement: '',
							name: 'FRENCH TOAST STACK',
							sideNote: '',
							orderNumber: 1,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Our breakfast sandwich: homemade falafel, egg and cheese on a toasted ciabatta bun, served with tomato and attitude.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							name: 'MERT',
							sideNote: '',
							orderNumber: 2,
							price: 38,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Spiced ramen noodles fried into an omlette with two eggs & fresh herbs with hot sauce on two pieces of toast.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							name: 'RAMEN OMLETTE',
							sideNote: '',
							orderNumber: 3,
							price: 25,
							unit: '',
						},
					],
					extraInfo: [],
				},
				{
					category: 'SALADS',
					orderNumber: 3,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							name: 'VEGAN SALAD',
							sideNote: '',
							orderNumber: 1,
							price: 20,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'GARDEN SALAD WITH GRILLED CHICKEN',
							sideNote: '',
							orderNumber: 2,
							price: 32,
							unit: '',
						},
					],
				},
				{
					category: 'SPECIAL SANDWICHES AND BURGERS',
					orderNumber: 4,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description:
								'Jumbo hot dog on a toasted bun with potato salad, pickled red onions, kale strings, french fries & ketchup',
							extraInfo: [],
							foodType: '',
							name: 'SAINT FRANK',
							sideNote: '',
							orderNumber: 1,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Chicken salad with our homemade mayo, pickled red onions, sun dried tomatoes, and kale strings on a French style baguette.',
							extraInfo: [],
							foodType: '',
							name: 'COLD CHICKEN SALAD BAGUETTE',
							sideNote: '',
							orderNumber: 2,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'A fresh baguette with homemade meatballs, tomato sauce, grated cheese, grilled onions and peppers.',
							extraInfo: [],
							foodType: '',
							name: 'JONAH',
							sideNote: '',
							orderNumber: 3,
							price: 50,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								"Crispy spicy fried chicken inspired by Prince's Hot Chicken in Nashville. Served on toasted bread with pickled onions and kale strings.",
							extraInfo: [],
							foodType: '',
							name: 'THE PRINCESS(SPICY FRIED CHICKEN)',
							sideNote: '',
							orderNumber: 4,
							price: 48,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'A Connecticut-style steamed cheeseburger with lettuce, tomato, pickles, onions, and our own sangria marmelade on the side.',
							extraInfo: [],
							foodType: '',
							name: 'BRISTOL BURGER',
							sideNote: '',
							orderNumber: 5,
							price: 44,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'A burger that shouldnt exist. A giant beef burger with onions and tomatoes & two grilled cheese sandwiches as the bun.',
							extraInfo: [],
							foodType: '',
							name: 'ABOMINATION',
							sideNote: '',
							orderNumber: 6,
							price: 65,
							priceTwo: 20,
							priceTwoInfo: 'with bacon',
							unit: '',
						},
					],
				},
				{
					category: 'TACO TUESDAY',
					orderNumber: 5,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description:
								'Crispy fried homemade tortillas filled with seasoned chicken, cheese, lettuce and our own tomato salsa.',
							extraInfo: [],
							foodType: '',
							name: 'CHICKEN TACO',
							sideNote: '',
							orderNumber: 1,
							price: 24,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Crispy fried homemade tortillas filled with seasoned beef, cheese, lettuce and our own tomato salsa.',
							extraInfo: [],
							foodType: '',
							name: 'BEEF TACO',
							sideNote: '',
							orderNumber: 2,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Crispy fried homemade tortillas filled with falafel, cheese, lettuce and our own tomato salsa.',
							extraInfo: [],
							foodType: 'VEGETARIAN',
							name: 'FALAFEL TACO',
							sideNote: '',
							orderNumber: 3,
							price: 23,
							unit: '',
						},
					],
				},
			],
			dessert: [
				{
					category: 'DESSERT',
					orderNumber: 1,
					image: [],
					items: [
						{
							name: 'HOMEMADE BROWNIE',
							description: '',
							price: 20,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 1,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'COOKIE',
							description: '',
							price: 18,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 2,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'ORANGE GLAZED SCONE',
							description: '',
							price: 20,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 3,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'APPLE CRUMBLE CAKE',
							description: '',
							price: 20,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 4,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'GLAZED DONUT',
							description: '',
							price: 17,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 5,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'LEMON GLAZED DONUT',
							description: '',
							price: 20,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 6,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'CHOCOLATE COVERED DONUT',
							description: '',
							price: 20,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 7,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'JAM FILLED DONUT',
							description: '',
							price: 21,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 8,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'COCONUT, CHOCOLATE & CARAMEL DONUT',
							description: '',
							price: 21,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 9,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'PEANUT BUTTER & CHOCOLATE DONUT',
							description: '',
							price: 21,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 10,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'SAFFRON CUSTART DONUT',
							description: '',
							price: 23,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 11,
							foodType: '',
							sideNote: '',
						},
						{
							name: 'CANDIED BACON & ESPRESSO GLAZED DONUT',
							description: '',
							price: 27,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 12,
							foodType: 'PORK',
							sideNote: '',
						},
						{
							name: 'SAN SEBASTIAN CHEESECAKE',
							description: '',
							price: 23,
							image: [],
							currency: 'TL',
							measurement: '',
							unit: '',
							extraInfo: [],
							orderNumber: 13,
							foodType: '',
							sideNote: '',
						},
					],
					extraInfo: [],
				},
			],
			drinks: [
				{
					category: 'OUR COFFEE BEANS',
					image: [],
					orderNumber: 1,
					items: [
						{
							image: [],
							name: 'EXILE BLEND',
							description:
								'A truly “all purpose” coffee. We blend our own beans for this signature coffee.',
							price: 70,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 1,
						},
						{
							image: [],
							name: 'CHURCH BLEND',
							description: 'A deep dark coffee blended from beans we roast ourselves.',
							price: 70,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 2,
						},
						{
							image: [],
							name: 'GUATEMALA ANTIGUA',
							description: 'A balanced bean with natural nutty, spice, and chocolate flavors.',
							price: 65,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 3,
						},
						{
							image: [],
							name: 'KENYA KIRINYAGA AA',
							description:
								'A full-bodied coffee with a complex spectrum of citrus and apple aromas.',
							price: 70,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 4,
						},
						{
							image: [],
							name: 'ETHIOPIA SIDAMO',
							description:
								'Bright with a silky rich body. Intense apricot, milk chocolate, and tarragon',
							price: 70,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 5,
						},
						{
							image: [],
							name: 'HONDURAS',
							description:
								'A well-rounded coffee with notes of fig, clove, orange peel, and chamomile.',
							price: 70,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 6,
						},
						{
							image: [],
							name: 'COLOMBIA',
							description:
								'A high quality balanced coffee with natural notes of baked apple, milk chocolate, citrus and hazelnut.',
							price: 75,
							currency: 'TL',
							measurement: 'gr',
							unit: '150',
							extraInfo: [],
							orderNumber: 7,
						},
					],
					extraInfo: [],
				},
				{
					category: 'NON-COFFEE HOT DRINKS',
					orderNumber: 2,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description:
								'Our own natural blend of hibiscus and dried orange peel, seved with a cinnamon stick.',
							extraInfo: [],
							foodType: '',
							name: 'HIBISCUS TEA',
							sideNote: '',
							orderNumber: 1,
							price: 22,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'An aromatic milky drink with fresh ginger, clove, star anise, cinnamon, cardamom, black pepper, nutmeg and black tea.',
							extraInfo: [],
							foodType: '',
							name: 'HOMEMADE CHAI TEA LATTE',
							sideNote: '',
							orderNumber: 2,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: 'Cadbury cocoa & genuine dark chocolate.',
							extraInfo: [],
							foodType: '',
							name: 'HOT CHOCOLATE',
							sideNote: '',
							orderNumber: 3,
							price: 30,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'MULLED WINE (seasonal)',
							sideNote: '',
							orderNumber: 4,
							price: 30,
							unit: '',
						},
					],
				},
				{
					category: 'NON-COFFEE COLD DRINKS',
					orderNumber: 3,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'LEMONADE',
							sideNote: '',
							orderNumber: 1,
							price: 24,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED HIBISCUS TEA',
							sideNote: '',
							orderNumber: 2,
							price: 23,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED CHAI TEA',
							sideNote: '',
							orderNumber: 3,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'CHAI TEA FLOAT(with ice cream)',
							sideNote: '',
							orderNumber: 4,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED CHOCOLATE',
							sideNote: '',
							orderNumber: 5,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'A sweet blend of red wine & fruit juice, served with frozen cherries and an orange slice.',
							extraInfo: [],
							foodType: '',
							name: 'CYPRUS SANGRIA(seasonal)',
							sideNote: '',
							orderNumber: 6,
							price: 33,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'FROZEN SANGRIA(seasonal)',
							sideNote: '',
							orderNumber: 7,
							price: 34,
							unit: '',
						},
					],
				},
				{
					category: 'POUROVER AND FILTERED COFFEE',
					orderNumber: 4,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'HARIO V60',
							sideNote: '',
							orderNumber: 1,
							price: 26,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'KALITA WAVE',
							sideNote: '',
							orderNumber: 2,
							price: 26,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'CLEVER',
							sideNote: '',
							orderNumber: 3,
							price: 26,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'AEROPASS',
							sideNote: '',
							orderNumber: 4,
							price: 26,
							unit: '',
						},
					],
				},
				{
					category: 'ESPRESSO DRINKS',
					orderNumber: 5,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'DOUBLE ESPRESSO',
							sideNote: '',
							orderNumber: 1,
							price: 20,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'AMERICANO',
							sideNote: '',
							orderNumber: 2,
							price: 23,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'LATTE',
							sideNote: '',
							orderNumber: 3,
							price: 25,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'CAPPUCCINO',
							sideNote: '',
							orderNumber: 4,
							price: 25,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'MOCHA',
							sideNote: '',
							orderNumber: 5,
							price: 30,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'AFFOGATO',
							sideNote: '',
							orderNumber: 6,
							price: 30,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description:
								'Homemade caramel sauce, a pinch of salt and an optional shot of brandy added to our classic latte.',
							extraInfo: [],
							foodType: '',
							name: 'SALTED CARAMEL LATTE',
							sideNote: '',
							orderNumber: 7,
							price: 30,
							priceTwo: 36,
							priceTwoInfo: 'with brandy',
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'TURKISH COFFEE',
							sideNote: '',
							orderNumber: 8,
							price: 18,
							unit: '',
						},
					],
				},
				{
					category: 'COLD COFFEE',
					orderNumber: 6,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED AMERICANO',
							sideNote: '',
							orderNumber: 1,
							price: 25,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED LATTE',
							sideNote: '',
							orderNumber: 2,
							price: 27,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED MOCHA',
							sideNote: '',
							orderNumber: 3,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED GINGER FRAPPE',
							sideNote: '',
							orderNumber: 4,
							price: 30,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ESPRESSO LEMONADE',
							sideNote: '',
							orderNumber: 5,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'COLD BREW',
							sideNote: '',
							orderNumber: 6,
							price: 27,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ORANGE SPICED AMERICANO',
							sideNote: '',
							orderNumber: 7,
							price: 28,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'FIZZY GINGER AMERICANO',
							sideNote: '',
							orderNumber: 8,
							price: 35,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'ICED CARAMEL LATTE',
							sideNote: '',
							orderNumber: 9,
							price: 32,
							unit: '',
						},
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'BLACK DEATH',
							sideNote: '',
							orderNumber: 10,
							price: 35,
							unit: '',
						},
					],
				},
				{
					category: 'EXTRAS',
					orderNumber: 7,
					image: [],
					items: [
						{
							image: [],
							currency: 'TL',
							description: '',
							extraInfo: [],
							foodType: '',
							name: 'SOYA MILK',
							sideNote: '',
							orderNumber: 1,
							price: 4,
							unit: '',
						},
					],
				},
			],
		};
	}
}
