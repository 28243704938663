import { Injectable, INJECTOR } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, authState, signInWithEmailAndPassword } from '@angular/fire/auth';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private auth: Auth, private router: Router) {}

	async isLoggedIn(): Promise<boolean> {
		return await new Promise((resolve, reject) => {
			authState(this.auth).subscribe((user) => {
				resolve(!!user);
			});
		}); //. ? true : false;
	}

	signIn(values: { email: string; password: string }) {
		return signInWithEmailAndPassword(this.auth, values.email, values.password);
	}

	get token(): any {
		return this.auth.currentUser.getIdToken().then((token) => {
			return token;
		});
	}

	async httpHeader() {
		const token = await this.token;
		const httpOptions = {
			headers: new HttpHeaders({
				'X-Firebase-ID-Token': token,
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			}),
		};
		return httpOptions;
	}

	logOut() {
		this.auth.signOut().then(() => {
			this.router.navigate(['/home']);
		});
	}
}
