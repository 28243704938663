import { NgModule } from '@angular/core';
import { SafeUrlPipe } from 'src/app/coffee-shop/admin/shared/pipes/safe-url.pipe';

let pipes = [SafeUrlPipe];

@NgModule({
	imports: [],
	declarations: [...pipes],
	exports: [...pipes],
})
export class PipeModule {
	static forRoot() {
		return {
			ngModule: PipeModule,
			providers: [],
		};
	}
}
