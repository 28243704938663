import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-exile-wine',
	templateUrl: './exile-wine.component.html',
	styleUrls: ['./exile-wine.component.scss'],
})
export class ExileWineComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
