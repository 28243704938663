import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { EventsComponent } from './events/events.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { NgbdModalContentComponent, NgbdModalComponent } from './modal/modal.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { InputsSectionComponent } from './inputs-section/inputs-section.component';
import { CrsSectionComponent } from './crs-section/crs-section.component';
import { NgwWowModule } from 'ngx-wow';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { ExileWineComponent } from './exile-wine/exile-wine.component';
import { OurWinePageComponent } from './our-wine/our-wine-page.component';
import { OurFoodComponent } from './our-food/our-food.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutsRoutingModule } from './layout/layouts-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { PipeModule } from './admin/shared/pipes/pipes-module';

import { GalleryComponent } from './menu/components/gallery/gallery.component';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
	resolvePersistenceEnabled = resolve;
});

@NgModule({
	declarations: [
		SignupComponent,
		NavbarComponent,
		FooterComponent,
		LoginComponent,
		MenuComponent,
		WhatWeOfferComponent,
		EventsComponent,
		AboutUsComponent,
		InputsSectionComponent,
		CrsSectionComponent,
		NgbdModalComponent,
		NgbdModalContentComponent,
		GalleryListComponent,
		ExileWineComponent,
		OurWinePageComponent,
		GiftCardComponent,
		OurFoodComponent,
		LayoutComponent,
		GalleryComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HomeModule,
		NgbModule,
		NgwWowModule,
		FormsModule,
		SwiperModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		HomeModule,
		FormsModule,
		NgbModule,
		RouterModule,
		JwBootstrapSwitchNg2Module,
		CarouselModule,
		LayoutsRoutingModule,
		PipeModule.forRoot(),
	],
})
export class CoffeeShopModule {}
