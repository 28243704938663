import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-our-wine',
	templateUrl: './our-wine.component.html',
	styleUrls: ['./our-wine.component.scss'],
})
export class OurWineComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
