import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-inputs-section',
	templateUrl: './inputs-section.component.html',
	styleUrls: ['./inputs-section.component.css'],
})
export class InputsSectionComponent implements OnInit {
	focus;
	focus1;
	focus5;
	constructor() {}

	ngOnInit() {}
}
