// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const region = 'europe-west1';
const useEmulatorArguments = false;
const API_URL = useEmulatorArguments
	? 'http://127.0.0.1:5001/exile-coffee/' + region
	: 'https://europe-west1-exile-coffee.cloudfunctions.net';
export const environment = {
	firebase: {
		projectId: 'exile-coffee',
		appId: '1:68865118132:web:e3442d038699b5c2342ce1',
		storageBucket: 'exile-coffee.appspot.com',
		apiKey: 'AIzaSyCujOysVXjIkzWV-nxFbRzToRiPaIvUM6s',
		authDomain: 'exile-coffee.firebaseapp.com',
		messagingSenderId: '68865118132',
		measurementId: 'G-C6KN0XZY70',
	},
	production: false,
	useEmulators: useEmulatorArguments,
	API: API_URL,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
