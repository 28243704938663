import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-contact-section',
	templateUrl: './contact-section.component.html',
	styleUrls: ['./contact-section.component.scss'],
})
export class ContactSectionComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
