import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-our-wine-page',
	templateUrl: './our-wine-page.component.html',
	styleUrls: ['./our-wine-page.component.scss'],
})
export class OurWinePageComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
