import { Component, Inject, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location, DOCUMENT } from '@angular/common';
import { NgwWowService } from 'ngx-wow';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
	showLoading = true;
	// tslint:disable-next-line:variable-name
	darkLogo = './assets/exile-2.png';
	whiteLogo = './assets/Exilewhite.png';
	logo = this.darkLogo;
	constructor(
		private renderer: Renderer2,
		private router: Router,
		@Inject(DOCUMENT) private document: any,
		private element: ElementRef,
		public location: Location,
		private wowService: NgwWowService
	) {}

	ngAfterViewInit() {
		setTimeout(() => {
			this.showLoading = false;
		}, 1000);
	}
}
