import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
	isOpen = false;
	constructor() {
		console.log('admin');
	}

	ngOnInit(): void {}

	toggleSidebar($event: any) {
		this.isOpen = !this.isOpen;
	}
}
