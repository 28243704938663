import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	email: '';
	total: '';
	name: '';
	date: '';
	code: '';
	amount: '';
	constructor() {}

	ngOnInit() {}
}
