import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
	imports: [NgbCarouselModule],
})
export class GalleryComponent {
	@Input() images: string[];
	@Input() name: string;

	constructor(public activeModal: NgbActiveModal, config: NgbCarouselConfig) {
		config.interval = 0;
	}
}
