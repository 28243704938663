import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataSourceService } from 'src/app/coffee-shop/admin/shared/services/data-source.service';
import { AuthService } from 'src/app/coffee-shop/services/auth.service';
import { user } from '@angular/fire/auth';
import { hasCustomClaim } from '@angular/fire/auth-guard';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const adminOnly = () => hasCustomClaim('admin');
@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(
		private api: DataSourceService,
		public auth: AuthService,
		private httpClient: HttpClient,
		private router: Router
	) {}

	login(params, isFoodBank = false) {
		return this.auth.signIn(params).then((response) => {
			if (!isFoodBank) {
				this.router.navigate(['/admin']);
			} else {
				return response;
			}
		});
	}

	logOut() {
		this.auth.logOut();
		// this.router.navigateByUrl('fdp/login');
	}

	async checkAuth(email: string) {
		const headers = await this.auth.httpHeader();
		return new Promise((resolve, reject) => {
			this.httpClient
				.post<any>(
					environment.API + '/dash/checkRole',
					{
						email,
					},
					headers
				)
				.subscribe(
					(res) => {
						resolve(res);
					},
					(error) => reject(error)
				);
		});
	}
}
