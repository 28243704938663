import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HomeComponent } from 'src/app/coffee-shop/home/home.component';
import { MenuListComponent } from 'src/app/coffee-shop/menu-section/menu-list.component';
import { AboutSectionComponent } from 'src/app/coffee-shop/about-section/about-section.component';
import { ContactSectionComponent } from 'src/app/coffee-shop/contact-section/contact-section.component';
import { ScrollSpyDirective } from 'src/app/coffee-shop/directives/scrol-spy';
import { OurCoffeeComponent } from 'src/app/coffee-shop/our-coffee-section/our-coffee.component';
import { CafeSectionComponent } from 'src/app/coffee-shop/cafe-section/cafe-section.component';
import { OurWineComponent } from 'src/app/coffee-shop/our-wine-section/our-wine.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		NgbCarouselModule,

		RouterModule,
		CarouselModule,
	],
	declarations: [
		HomeComponent,
		MenuListComponent,
		AboutSectionComponent,
		CafeSectionComponent,
		OurCoffeeComponent,
		OurWineComponent,
		ContactSectionComponent,
		ScrollSpyDirective,
	],
	exports: [HomeComponent],
	providers: [],
})
export class HomeModule {}
