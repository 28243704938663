import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-gallery-list',
	templateUrl: './gallery-list.component.html',
	styleUrls: ['./gallery-list.component.scss'],
})
export class GalleryListComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
